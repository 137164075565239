@import url(https://fonts.googleapis.com/css2?family=Space+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* http://stencil.wiki/colors */

html {
    margin: 0;
    quotes: "\201C" "\201D";
    /* position: relative; */
    overflow-x: hidden;
    /* COLORS */
    --skyblue: rgba(98, 168, 229, 0.25);
    --skyblue-plus: rgba(98, 168, 229, 0.75);
    --poppy: rgba(255, 108, 47, 0.75);
    --darkblue: rgba(35, 91, 168, 1);
    --modal-bg:  rgba(98, 168, 229, 1.0);
    background: linear-gradient(rgba(98, 168, 229, 0.75), rgba(98, 168, 229, 0.25));
    background: linear-gradient(var(--skyblue-plus), var(--skyblue));
}

body {
    /* position: relative; */
    margin: 0;
    font-family: 'Space Mono', monospace;
    font-weight: 700;
    font-size: 20px;
    background: linear-gradient(rgba(98, 168, 229, 0.75), rgba(98, 168, 229, 0.25));
    background: linear-gradient(var(--skyblue-plus), var(--skyblue));
}

.video-react-big-play-button {
    display: none !important;
}

a {
    color: rgba(35, 91, 168, 1);
    color: var(--darkblue);
    background: rgba(255, 108, 47, 0.75);
    background: var(--poppy);
}

.accent {
    color: rgba(35, 91, 168, 1);
    color: var(--darkblue);
    background: rgba(255, 108, 47, 0.75);
    background: var(--poppy); 
}
